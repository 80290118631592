import { Button, Card, Textarea, Typography } from "@material-tailwind/react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";


export default function ProfileTable(props) {
  const { t } = useTranslation();
  const [teachingLanguage, setTeachingLanguage] = useState("");
  const [teachingLanguageSkill, setTeachingLanguageSkill] = useState("");
  const [changeIntroduction, setChangeIntroduction] = useState("");
  const [interestedTopic, setInterestedTopic] = useState("");
  const [creditAdjustmentRate, setCreditAdjustmentRate] = useState("");
  const [isChange, setIsChange] = useState("");

  const TABLE_HEAD = [
    "No",
    t("become_a_leader.teaching_lang"),
    t("become_a_leader.teaching_lang_level"),
    t("become_a_leader.course_intro"),
    t("become_a_leader.topics"),
    t("become_a_leader.rate"),
    ""
  ];

  const updateList = (index) => {
    if (!teachingLanguage || !teachingLanguageSkill || !changeIntroduction) {
      toast.warn(t("generic.enter_all"), {
        position: "bottom-right",
      });
      return;
    }

    if (interestedTopic.length > 200) {
      toast.warn("Interested topics must have no more than 200 characters.", {
        position: "bottom-right",
      });
      return;
    }

    props.update(
      teachingLanguage,
      teachingLanguageSkill,
      changeIntroduction,
      interestedTopic,
      creditAdjustmentRate,
      index
    );
    setIsChange("");
  };

  const cancelUpdate = () => {
    setIsChange("");
    setTeachingLanguage("");
    setTeachingLanguageSkill("");
    setChangeIntroduction("");
    setInterestedTopic("");
    setCreditAdjustmentRate("");
  };


  // Generate an array of integers within a given range (e.g., -100 to 100)
  const generateIntegerOptions = (min, max) => {
    const options = [];
    for (let i = min; i <= max; i++) {
      options.push(i);
    }
    return options;
  };

  // Generate a range of integers, for example, from -100 to 100
  const integerOptions = generateIntegerOptions(0, 600);

  return (
    <Card className="h-[250px] w-full overflow-scroll bg-[#F5F5DC]">
      <table className="w-full min-w-max table-auto text-center">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 p-4 bg-[#F5F5DC]"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.TABLE_ROWS &&
            props.TABLE_ROWS?.results?.map(
              (
                {
                  introduction,
                  teaching_language,
                  teaching_language_skill_level,
                  interested_topics,
                  credit_adjustment_rate,
                  id,
                },
                index
              ) => {
                const isLast = index === props.TABLE_ROWS.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";
                return (
                  <tr key={index + 1}>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {index + 1}
                      </Typography>
                    </td>
                    <td className={classes}>
                      {isChange === index ? (
                        <select
                          id="languages"
                          value={teachingLanguage}
                          onChange={(e) => {
                            setTeachingLanguage(e.target.value);
                          }}
                          className="bg-transparent border border-gray-400 text-[#70818b] text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-700"
                        >
                          <option value="">Choose a language</option>
                          <option value="EN">English</option>
                          <option value="ES">Spanish</option>
                          <option value="CN">Chinese</option>
                        </select>
                      ) : (
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {teaching_language === "EN" && "English"}
                          {teaching_language === "CN" && "Chinese"}
                          {teaching_language === "ES" && "Spanish"}
                        </Typography>
                      )}
                    </td>
                    <td className={classes}>
                      {isChange === index ? (
                        <select
                          id="skill"
                          value={teachingLanguageSkill}
                          onChange={(e) => {
                            setTeachingLanguageSkill(e.target.value);
                          }}
                          className="bg-transparent border border-gray-400 text-[#70818b] text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-700"
                        >
                          <option value="">Base language level</option>
                          <option value="1">Beginner</option>
                          <option value="2">Know a little</option>
                          <option value="3">Intermediate</option>
                          <option value="4">Fluent Speaker</option>
                          <option value="5">Native Speaker</option>
                        </select>
                      ) : (
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {teaching_language_skill_level === 1 && "Beginner"}
                          {teaching_language_skill_level === 2 &&
                            "Know a little"}
                          {teaching_language_skill_level === 3 &&
                            "Intermediate"}
                          {teaching_language_skill_level === 4 &&
                            "Fluent Speaker"}
                          {teaching_language_skill_level === 5 &&
                            "Native Speaker"}
                        </Typography>
                      )}
                    </td>
                    <td className={classes}>
                      {isChange === index ? (
                        <Textarea
                          label="Introduction"
                          value={changeIntroduction}
                          onChange={(e) =>
                            setChangeIntroduction(e.target.value)
                          }
                        />
                      ) : (
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-left rtl:text-right text-wrap w-36"
                        >
                          {introduction}
                        </Typography>
                      )}
                    </td>
                    <td className={classes}>
                      {isChange === index ? (
                        <Textarea
                          label="Interested-topic"
                          value={interestedTopic}
                          onChange={(e) => setInterestedTopic(e.target.value)}
                        />
                      ) : (
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-left rtl:text-right text-wrap w-36"
                        >
                          {interested_topics}
                        </Typography>
                      )}
                    </td>
                    <td className={classes}>
                      {isChange === index ? (
                        <div>
                          <label>Lingo Credits for a 60-minute course</label>
                              <select value={creditAdjustmentRate} onChange={(e) => setCreditAdjustmentRate(e.target.value)}>
                                {integerOptions.map((num) => (
                                  <option key={num} value={num}>
                                    {num}
                                  </option>
                                ))}
                              </select>
                        </div>
                      ) : (

                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-left rtl:text-right text-wrap w-36"
                        >
                          {Math.ceil(credit_adjustment_rate * 60) + " Lingo Credits"}
                        </Typography>

                      )}
                    </td>
                    <td className={classes}>
                      <div className="flex justify-center">
                        {isChange === "" && (
                          <Button
                            color="amber"
                            className="mr-4"
                            onClick={(e) => {
                              setIsChange(index);
                              setTeachingLanguage(teaching_language);
                              setTeachingLanguageSkill(
                                teaching_language_skill_level
                              );
                              setChangeIntroduction(introduction);
                              setInterestedTopic(interested_topics);
                              setCreditAdjustmentRate(credit_adjustment_rate);
                            }}
                          >
                            {t("generic.edit")}
                          </Button>
                        )}
                        {index === isChange && (
                          <div className="flex">
                            <Button
                              color="blue-gray"
                              onClick={cancelUpdate}
                              className="mr-4"
                            >
                              {t("generic.cancel")}
                            </Button>
                            <Button
                              color="green"
                              onClick={() => updateList(index)}
                              className="mr-4"
                            >
                              {t("generic.update")}
                            </Button>
                          </div>
                        )}
                        <Button color="red" onClick={() => props.delete(id)}>
                          {t("generic.delete")}
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              }
            )}
        </tbody>
      </table>
    </Card>
  );
}
