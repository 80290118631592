import React, { useEffect, useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
  Card,
  CardHeader,
  Typography,
  CardBody,
  CardFooter,
  Input,
} from "@material-tailwind/react";
import { MenuBar } from "../../components/Menu/Menu";
import Pagination from "../../components/Pagination/Pagination";
import { getTransactionList } from "../../store/actions/payment.action";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import { transform_payment_action } from "../../components/Transformation/Transformations";
import { useTranslation } from "react-i18next";

export default function PaymentHistory() {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const transactions = useSelector((state) => state.payment.transactions);
  const transactionLoader = useSelector(
    (state) => state.payment.transactions_loader
  );
  const [page, setPage] = useState(1);
  const totalPages = Math.ceil(transactions.count / 10);
  let totalCredit = transactions?.results?.map(x => x.amount).reduce((partialSum, a) => partialSum + a, 0);

  const TABLE_HEAD = [
    "No",
    t("payment.time"),
    t("payment.id"),
    // "Status",
    t("payment.description"),
    t("payment.note"),
    t("payment.amount"),
  ];
  const TABLE_ROWS = [];
  // const TABLE_ROWS = [
  //   {
  //     id: "$2,500",
  //     date: "06/2026 Wed 3:00pm",
  //     status: "paid",
  //     surmary: "For Meeting",
  //   },
  //   {
  //     id: "$2,500",
  //     date: "06/2026 Wed 3:00pm",
  //     status: "paid",
  //     surmary: "For Credits",
  //   },
  //   {
  //     id: "$5,000",
  //     date: "06/2026 Wed 3:00pm",
  //     status: "paid",
  //     surmary: "For Meeting",
  //   },
  //   {
  //     id: "$3,400",
  //     date: "06/2026 Wed 3:00pm",
  //     status: "pending",
  //     surmary: "For Meeting",
  //   },
  //   {
  //     id: "$1,000",
  //     date: "06/2026 Wed 3:00pm",
  //     status: "paid",
  //     surmary: "For Meeting",
  //   },
  //   {
  //     id: "$14,000",
  //     date: "06/2026 Wed 3:00pm",
  //     status: "cancelled",
  //     surmary: "For Meeting",
  //   },
  // ];

  const closeMenuBar = () => {
    setMenuOpen(false);
  };

  // const openMenuBar = () => {
  //   setMenuOpen(true);
  // };

  useEffect(() => {
    dispatch(getTransactionList({ token, page }));
  }, [dispatch, token, page]);

  return (
    <div className="max-w-screen-2xl mx-auto h-full">
      <div className="w-full flex flex-col gap-8 p-4 md:p-12">
        {/* <p className="flex justify-center text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-bold">
          My Payment Histories
        </p> */}
        <div className="flex flex-col">
          <p className="flex text-2xl justify-center md:text-3xl font-semibold">
            {t("payment.total")}: {totalCredit}
          </p>
          <Card className="h-full w-full bg-[#F5F5DC] border-[#004225] border-[1px]">
            <CardHeader
              floated={false}
              shadow={false}
              className="rounded-none bg-[#F5F5DC]"
            >
              <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
                <div>
                  <Typography variant="h5" color="blue-gray">
                    {t("payment.hist")}
                  </Typography>
                  <Typography color="gray" className="mt-1 font-normal">
                    {t("payment.table_text")}
                  </Typography>
                </div>
                <div className="flex w-full shrink-0 gap-2 md:w-max">
                  <div className="w-full md:w-72">
                    <Input
                      label="Search"
                      icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                    />
                  </div>
                </div>
              </div>
            </CardHeader>
            <CardBody className="overflow-scroll px-0">
              <table className="w-full min-w-max table-auto text-left">
                <thead className="bg-[#F5F5DC]">
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                      >
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {transactionLoader ? (
                    <tr>
                      <td
                        colSpan={TABLE_HEAD?.length}
                        className="p-4 text-center font-semibold"
                      >
                        <Loader />
                      </td>
                    </tr>
                  ) : transactions?.results?.length > 0 ? (
                    transactions.results?.map(
                      (
                        { id, happen_at, status, description, note, amount },
                        index
                      ) => {
                        const isLast = index === TABLE_ROWS?.length - 1;
                        const classes = isLast
                          ? "p-4"
                          : "p-4 border-b border-blue-gray-50";

                        return (
                          <tr key={index + 1}>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-bold"
                                >
                                  {index + 1}
                                </Typography>
                              </div>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {happen_at}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {id}
                              </Typography>
                            </td>
                            {/* <td className={classes}>
                            <div className="w-max">
                              <Chip
                                size="sm"
                                variant="ghost"
                                value={status}
                                color={
                                  status === "paid"
                                    ? "green"
                                    : status === "pending"
                                    ? "amber"
                                    : "red"
                                }
                              />
                            </div>
                          </td> */}
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal opacity-70"
                                  >
                                    {t(transform_payment_action(description)) ||
                                      ""}
                                  </Typography>
                                </div>
                              </div>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {note || ""}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {amount}
                              </Typography>
                            </td>
                          </tr>
                        );
                      }
                    )
                  ) : (
                    <tr>
                      <td
                        colSpan={TABLE_HEAD?.length}
                        className="p-4 text-center font-semibold"
                      >
                        No records found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </CardBody>
            {totalPages > 0 && (
              <CardFooter className="flex items-center justify-center border-t border-blue-gray-50 p-4">
                <Pagination
                  pageNumber={totalPages}
                  page={page}
                  setPage={setPage}
                />
              </CardFooter>
            )}
          </Card>
        </div>
      </div>
      <MenuBar open={menuOpen} close={closeMenuBar} />
    </div>
  );
}
