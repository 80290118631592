import {
  GET_USER_BYEMAIL,
  SET_PROFILE_REGISTER,
  SET_PROFILE_UPDATE,
  GET_ALLUSER,
  GET_ALLUSER_LOADER,
  GET_CURRENT_LEADER,
  CREATE_WISH,
  GET_ALL_SHORT_LEADER,
  GET_ALL_LEADER,
  DELETE_LEADER_PROFILE,
  CREATE_LEADER_PROFILE,
  UPDATE_LEADER_PROFILE,
} from "../actions/user.action";

const initialState = {
  currentUser: [],
  currentLeader: null,
  allUser: [],
  allShortLeaders: [],
  allUsersLoader: true,
  wish: null,
  getLeaders: [],
  deleteLeaderProfile: null,
  createLeaderProfile: [],
  updateLeaderProfile: [],
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROFILE_REGISTER:
      return {
        ...state,
        currentUser: action.payload.user,
      };

    case SET_PROFILE_UPDATE:
      return {
        ...state,
        currentUser: action.payload.user,
      };

    case GET_USER_BYEMAIL:
      return {
        ...state,
        currentUser: action.payload,
      };

    case GET_ALLUSER:
      return {
        ...state,
        allUser: action.payload,
      };
    case GET_ALL_SHORT_LEADER:
      return {
        ...state,
        allShortLeaders: action.payload,
        allUsersLoader: false,
      };

    case GET_ALLUSER_LOADER:
      return {
        ...state,
        allUsersLoader: action.payload,
      };

    case GET_CURRENT_LEADER:
      return {
        ...state,
        currentLeader: action.payload,
      };
    case GET_ALL_LEADER:
      return {
        ...state,
        getLeaders: action.payload,
      };
    case CREATE_LEADER_PROFILE:
      return {
        ...state,
        createLeaderProfile: action.payload,
      };
    case UPDATE_LEADER_PROFILE:
      return {
        ...state,
        updateLeaderProfile: action.payload,
      };
    case DELETE_LEADER_PROFILE:
      return {
        ...state,
        deleteLeaderProfile: action.payload,
      };
    case CREATE_WISH:
      return {
        ...state,
        wish: action.payload,
        error: null,
      };

    default:
      return state;
  }
}
