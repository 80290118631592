import {
  GET_PAYMENT_KEY,
  GET_TRANSACTION_LIST,
  GET_TRANSACTION_LIST_LOADER
} from "../actions/payment.action";

const initialState = {
  clientSecret: null,
  transactions: [],
  transactions_loader: true,
};

export default function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PAYMENT_KEY:
      return {
        ...state,
        clientSecret: action?.payload?.clientSecret,
      };
    case GET_TRANSACTION_LIST:
      return {
        ...state,
        transactions: action.payload,
      };
    case GET_TRANSACTION_LIST_LOADER:
      return {
        ...state,
        transactions_loader: action.payload,
      };
    default:
      return state;
  }
}
