import React from "react";

export default function WeekDayButton(props) {
  return (
    <button
      className={`w-[100px] rounded-[10px] md:rounded-[20px] bg-[#004225] text-[#F5F5F5] text-xl md:text-3xl xl:text-3xl font-normal`}
      //   ${
      //   props.name === props.nowState
      //     ? "bg-[#004225c7] text-[#000000]"
      //     : `"bg-[#004225c7] text-[#000000] "opacity-50"}`
      // }
      // disabled={true}
      // onClick={props.onClick}
    >
      {props.name}
    </button>
  );
}
