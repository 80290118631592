import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createMeeting } from "../../store/actions/meeting.action";

import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import ConfirmationCard from "../../components/Cards/ConfirmationCard";
import { MenuBar } from "../../components/Menu/Menu";
import { getLeaderById } from "../../store/actions/user.action";
import { getUserByEmail } from "../../store/actions/user.action";
import { useTranslation } from "react-i18next";
import {
  transform_timezone,
  transform_languages,
} from "../../components/Transformation/Transformations";

export default function Confirmation() {
  const [menuOpen, setMenuOpen] = useState(false);
  const { id } = useParams();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const mail = localStorage.getItem("mail");
  const { state } = useLocation();
  const { t } = useTranslation();

  const currentLeader = useSelector((state) => state.user.currentLeader);
  const currentUser = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    dispatch(getUserByEmail({ token, mail }));
    if (token && id) dispatch(getLeaderById({ token, id }));
  }, [dispatch, id, mail, token]);

  const closeMenuBar = () => {
    setMenuOpen(false);
  };

  const openMenuBar = () => {
    setMenuOpen(true);
  };

  const submitMeeting = () => {
    dispatch(
      createMeeting({
        follower: currentUser?.id,
        leader_profile: currentLeader?.id,
        teaching_language: currentLeader?.teaching_language,
        meeting_happen_at: {
          date: state?.date,
          timezone: currentUser?.timezone,
        },
        meeting_length: state?.length,
        token: token,
      })
    );
    navigation("/user/meetings");
  };
  return (
    <div className="max-w-screen-2xl mx-auto p-4 xl:p-0">
      <Header openMenu={openMenuBar} />
      {currentLeader && (
        <>
          <div className="flex justify-center mt-10 ml-8 md:ml-0">
            <span className="text-3xl md:text-4xl font-semibold overline decoration-green-950">
              {t("meeting_confirmation.I")}
            </span>
            <span className="text-3xl md:text-4xl font-semibold">
              {t("meeting_confirmation.title")}
            </span>
          </div>
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-20 justify-center items-center m-12">
            <div className="flex flex-col items-center mt-28">
              <img
                src={
                  currentLeader?.owner?.image ||
                  "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724686661/Nobody_bao3i5.svg"
                }
                alt="UserImage"
                className="rounded-full boder-solid border-[10px] border-[#004225] w-[200px] h-[200px] sm:w-[300px] sm:h-[300px] md:w-[400px] md:h-[400px]"
              />
              <div className="text-3xl font-semibold mt-8">
                {currentLeader?.owner?.first_name +
                  " " +
                  currentLeader?.owner?.last_name}
              </div>
              <div className="text-xl font-normal mt-2 ">
                <span className="font-semibold">
                  {t("meeting_confirmation.leader_lang")}
                </span>
                &nbsp;
                {t(transform_languages(currentLeader?.teaching_language))}
              </div>
              <div className="text-xl font-normal mt-2 ">
                <span className="font-semibold">
                  {t("meeting_confirmation.leader_timezone")}
                </span>
                &nbsp;
                {t(transform_timezone(currentLeader?.owner?.timezone))}
              </div>
            </div>
            <div className="flex justify-center items-center">
              <ConfirmationCard
                image={
                  currentUser?.image ||
                  "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724686661/Nobody_bao3i5.svg"
                }
                name={currentUser?.first_name + " " + currentUser?.last_name}
                job={"confirmation_card.student"}
                credits={currentUser?.credits}
                meeting_happen_at={state?.date}
                duration={state?.length}
                teaching_language={currentLeader.teaching_language}
                meetingCancel={(e) => navigation(`/leaders/profile/${id}`)}
                meetingSubmit={submitMeeting}
                credit_adjustment_rate={currentLeader?.credit_adjustment_rate}
              />
            </div>
          </div>
        </>
      )}
      <Footer />
      <MenuBar open={menuOpen} close={closeMenuBar} />
    </div>
  );
}
