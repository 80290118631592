import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { transform_languages, transform_timezone } from "../../components/Transformation/Transformations";


export default function ConfirmationCard(props) {
  const [formattedMeetingDateTime, setFormattedMeetingDateTime] = useState();
  const [neededCredits, setNeededCredits] = useState(0);
  const userProfile = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (props?.meeting_happen_at) {
      // Parse the date string using the user's timezone
      const localDateTime = moment(props?.meeting_happen_at);

      // Format the date and time
      const formattedDate = localDateTime.format("DD/MM/YYYY");
      const formattedTime = localDateTime.format("HH:mm");

      setFormattedMeetingDateTime({
        date: formattedDate,
        time: formattedTime,
      });
    }
  }, [props.meeting_happen_at, userProfile]);

  useEffect(() => {
    // Calculate needed credits based on the meeting duration and leader_profile's credit_adjustment_rate
    const calculateNeededCredits = () => {
      const durationMinutes = parseInt(props.duration, 10);
      const creditsRequired = Math.ceil(durationMinutes / process.env.REACT_APP_CREDIT_PER_MINUTE * props.credit_adjustment_rate);
      setNeededCredits(creditsRequired);
    };

    calculateNeededCredits();
  }, [props.duration, props.credit_adjustment_rate]);

  const hasEnoughCredits = props.credits >= neededCredits;

  const handleNavigate = () => {
    navigate("/user/payment");
  };

  return (
    <div className="flex flex-col rounded-tl-[30px] rounded-br-[30px] shadow-lg bg-white w-[320px] h-auto md:w-[520px] md:h-auto p-6">
      <div className="flex gap-5 md:gap-10 border-b-2 pb-4">
        <div className="flex-shrink-0">
          <img
            src={props.image}
            alt="userImage"
            className="rounded-full border-solid border-2 border-gray-300 w-[80px] h-[80px] md:w-[110px] md:h-[110px]"
          />
        </div>
        <div className="flex flex-col justify-center">
          <div className="text-2xl font-bold text-black">{props.name}</div>
          <div className="text-sm font-medium text-gray-800 mt-0">
            {t(props.job)}
            <span className="text-lg font-medium ml-2">
              {t("confirmation_card.your_credit")}
              {props.credits}
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 mt-4">
        <div className="flex justify-between">
          <div className="text-xl font-semibold text-gray-900">{t("confirmation_card.dt")}</div>
          <div className="text-lg font-normal text-gray-800">
            {`${formattedMeetingDateTime?.date} ${formattedMeetingDateTime?.time}`}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="text-xl font-semibold text-gray-900">{t("confirmation_card.du")}</div>
          <div className="text-lg font-normal text-gray-800">
            {props.duration} {t("generic.minute")}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="text-xl font-semibold text-gray-900">{t("confirmation_card.lang")}</div>
          <div className="text-lg font-normal text-gray-800">{t(transform_languages(props.teaching_language))}</div>
        </div>
        <div className="flex justify-between">
          <div className="text-xl font-semibold text-gray-900">{t("confirmation_card.time")}</div>
          <div className="text-lg font-normal text-gray-800">
            {t(transform_timezone(userProfile?.timezone))}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="text-xl font-semibold text-gray-900">
            {t("confirmation_card.need_credits")}
          </div>
          <div className="text-lg font-normal text-gray-800">
            {neededCredits}
          </div>
        </div>
        {neededCredits > props.credits && (
          <div
            className="text-sm font-medium text-red-400 cursor-pointer underline w-[55%]"
            onClick={() => {
              handleNavigate();
            }}
          >
            {t("confirmation_card.error_text")}
          </div>
        )}
      </div>
      <div className="flex justify-center mt-6 gap-4">
        <button
          className="bg-[#195d40] hover:bg-[#004225] transition-colors w-[120px] h-[40px] md:w-[145px] md:h-[50px] text-xl xl:text-2xl font-medium text-white rounded-2xl md:rounded-3xl"
          onClick={props.meetingCancel}
        >
          {t("confirmation_card.cancel_button")}
        </button>
        <button
          className={`w-[120px] h-[40px] md:w-[145px] md:h-[50px] text-xl xl:text-2xl font-medium text-white rounded-2xl md:rounded-3xl transition-colors ${
            hasEnoughCredits
              ? "bg-[#195d40] hover:bg-[#004225]"
              : "bg-gray-500 cursor-not-allowed"
          }`}
          onClick={props.meetingSubmit}
          disabled={!hasEnoughCredits}
        >
          {t("confirmation_card.submit_button")}
        </button>
      </div>
    </div>
  );
}
