import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { MenuBar } from "../../components/Menu/Menu";
import { getCurrentMeeting } from "../../store/actions/meeting.action";
import { transform_languages } from "../../components/Transformation/Transformations";
import { useTranslation } from "react-i18next";

export default function Cancel() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [menuOpen, setMenuOpen] = useState(false);
  const token = localStorage.getItem("token");
  const mail = localStorage.getItem("mail");
  const currentMeeting = useSelector((state) => state.meeting.currentMeeting);

  useEffect(() => {
    dispatch(getCurrentMeeting({ token, id }));
  }, [dispatch, token, mail, id]);

  const closeMenuBar = () => {
    setMenuOpen(false);
  };

  const openMenuBar = () => {
    setMenuOpen(true);
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Months are zero-indexed
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;

    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <div className="max-w-screen-2xl mx-auto p-4 h-full">
      <Header openMenu={openMenuBar} />
      {/* {currentLearnerUser && currentMeeting && ( */}
      <>
        <div className="flex flex-col md:flex-row">
          <div className="basis-1/2 flex flex-col items-center justify-center">
            <div className="flex justify-center">
              <img
                src={
                  "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724620285/Cancel_gxfb2b.jpg"
                }
                alt="CancelImage"
                className="w-[200px] h-[200px] md:w-[300px] md:h-[300px] xl:w-[400px] xl:h-[400px]"
              />
            </div>
            <p className="text-center text-xl md:text-2xl xl:text-3xl mb-20">
              {t("meeting.cancelled")}
            </p>
          </div>
          <div className="basis-1/2 flex flex-col items-center justify-center">
            <img
              src={
                currentMeeting.follower?.image ||
                "https://res.cloudinary.com/do6t8sxgo/image/upload/v1724686661/Nobody_bao3i5.svg"
              }
              alt="UserImage"
              className="rounded-full boder-solid border-[10px] border-[#004225] w-[200px] h-[200px] md:w-[200px] md:h-[200px] xl:w-[300px] xl:h-[300px]"
            />
            <div className="text-3xl font-semibold mt-8">
              {currentMeeting.follower?.first_name +
                " " +
                currentMeeting.follower?.last_name}
            </div>
            <div className="text-xl font-normal mt-2">
              {t(transform_languages(currentMeeting.teaching_language))}
            </div>
            <div className="flex flex-col mt-4 items-center gap-4">
              <div className="flex">
                <div className="text-2xl font-semibold">
                  {t("confirmation_card.dt")}
                </div>
                <div className="text-2xl font-normal">
                  {" "}
                  {formatDateTime(currentMeeting.meeting_happen_at)}
                </div>
              </div>
              {/* <div className="flex">
                  <div className="text-2xl font-semibold">Time:</div>
                  <div className="text-2xl font-normal">
                    {" "}
                    {currentMeeting.meeting_time}
                  </div>
                </div> */}
              <div className="flex">
                <div className="text-2xl font-semibold">
                  {t("confirmation_card.du")}
                </div>
                <div className="text-2xl font-normal">
                  {" "}
                  {currentMeeting.meeting_length} {t("generic.minute")}
                </div>
              </div>
              <div className="flex">
                <div className="text-2xl font-semibold">
                  {t("confirmation_card.lang")}
                </div>
                <div className="text-2xl font-normal">
                  {t(transform_languages(currentMeeting.teaching_language))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      {/* )} */}
      <Footer />
      <MenuBar open={menuOpen} close={closeMenuBar} />
    </div>
  );
}
