import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/auth.reducer";
import userReducer from "./reducers/user.reducer";
import paymentReducer from "./reducers/payment.reducer";
import meetingReducer from "./reducers/meeting.reducer";

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    payment: paymentReducer,
    meeting: meetingReducer,
  },
});

export default store;
