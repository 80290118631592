import React, { useState } from "react";
import Header from "../../components/header/Header";
import { MenuBar } from "../../components/Menu/Menu";

const TermsConditions = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const closeMenuBar = () => {
    setMenuOpen(false);
  };

  const openMenuBar = () => {
    setMenuOpen(true);
  };
  return (
    <>
      <div className="max-w-screen-2xl mx-auto px-4">
        <Header openMenu={openMenuBar} />
      </div>
      <div className="max-w-screen-2xl mx-auto px-8 py-8">
        <h2 className="text-3xl font-bold mb-4">Terms & Conditions</h2>
        <section className="text-gray-900 mb-4 font-bold text-xl">
          1. Acceptance of Terms
          <p className="font-medium text-base">
            Welcome to Lingo Club! By accessing or using the LingoClub platform
            (the "Service"), you agree to be bound by these Terms and
            Conditions. If you disagree with any part of the terms, you may not
            access the Service.
          </p>
        </section>
        <section className="text-gray-900 mb-4 font-bold text-xl">
          2. Description of Service
          <p className="font-medium text-base">
            LingoClub is an online platform that facilitates language learning
            meetings between learners and leaders. Learners pay for meetings
            using Lingo Credits, and leaders earn Lingo Credits for conducting
            meetings.
          </p>
        </section>
        <section className="text-gray-900 mb-4 font-bold text-xl">
          3. User Registration
          <p className="font-medium text-base">
            You must register for an account to use the Service. You agree to
            provide accurate, current, and complete information during the
            registration process. You are responsible for maintaining the
            confidentiality of your account and password.
          </p>
        </section>
        <section className="text-gray-900 mb-4 font-bold text-xl">
          4. Lingo Credits
          <p className="font-medium text-base">
            Lingo Credits are used as the currency for transactions on Lingo
            Club. Learners purchase Lingo Credits, which can be used to book
            meetings. Leaders earn Lingo Credits based on the duration of the
            meetings conducted, with a standard rate set for a meeting of
            15-minute or above. The rate may vary depending on the service level
            and the Leader's expertise.
          </p>
        </section>
        <section className="text-gray-900 mb-4 font-bold text-xl">
          5. Payments and Earnings
          <p className="font-medium text-base">
            Payments for Lingo Credits can be made using various payment methods
            provided on our platform. Once purchased, Lingo Credits cannot be
            refunded but can be used within the platform for booking meetings.
            Leaders can redeem earned Lingo Credits for monetary payment
            according to LingoClub's payout policies.
          </p>
        </section>
        <section className="text-gray-900 mb-4 font-bold text-xl">
          6. User Responsibilities
          <p className="font-medium text-base">
            Users are responsible for their conduct while using our platform.
            You agree not to use the platform for any unlawful or prohibited
            activities. Lingo Club reserves the right to suspend or terminate
            your account if you violate these Terms.
          </p>
        </section>
        <section className="text-gray-900 mb-4 font-bold text-xl">
          7. Intellectual Property
          <p className="font-medium text-base">
            All content provided by Leaders on the platform is their
            responsibility. Lingo Club is not liable for any content posted or
            shared by users. Users grant Lingo Club a non-exclusive,
            royalty-free, worldwide license to use, distribute, and display
            their content for the purpose of operating the platform.
          </p>
        </section>
        <section className="text-gray-900 mb-4 font-bold text-xl">
          8. Termination
          <p className="font-medium text-base">
            Lingo Club reserves the right to terminate or suspend access to the
            platform without prior notice if you violate these Terms or engage
            in fraudulent or illegal activities.
          </p>
        </section>
        <section className="text-gray-900 mb-4 font-bold text-xl">
          9. Changes to Terms and Conditions
          <p className="font-medium text-base">
            We may update these Terms from time to time. Any changes will be
            posted on this page, and continued use of the platform constitutes
            acceptance of the new Terms.
          </p>
        </section>
        <section className="text-gray-900 mb-4 font-bold text-xl">
          10. Contact Us
          <p className="font-medium text-base">
            For any questions or concerns regarding these Terms, please contact
            us at [insert contact information like Email or contact number].
          </p>
        </section>
        <MenuBar open={menuOpen} close={closeMenuBar} />
      </div>
    </>
  );
};

export default TermsConditions;
