import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Select,
  Option,
  Textarea,
} from "@material-tailwind/react";
import { useDispatch } from "react-redux";
import { registerLeaderProfile } from "../../store/actions/user.action";
import { useTranslation } from "react-i18next";


export function AddLeaderProfile(props) {
  // const [training_language, setTraining_language] = useState("");
  // const [
  //   training_language_skill_level,
  //   setTraining_language_skill_level,
  // ] = useState("");

  const { t } = useTranslation();
  const [teaching_language, setTeaching_language] = useState();
  const [
    teaching_language_skill_level,
    setTeaching_language_skill_level,
  ] = useState();
  const [introduction, setIntroduction] = useState("");
  const [interestedTopic, setInterestedTopic] = useState("");
  const [creditAdjustmentRate, setCreditAdjustmentRate] = useState(60);

  // const [base_language, setBase_language] = useState("");
  // const [base_language_skill_level, setBase_language_skill_level] = useState(
  //   ""
  // );
  const token = localStorage.getItem("token");
  const mail = localStorage.getItem("mail");

  const dispatch = useDispatch();

  const sendData = () => {
    if (
      !teaching_language ||
      !teaching_language_skill_level ||
      !interestedTopic ||
      // !base_language_skill_level ||
      !introduction
    ) {
      toast.warn(t("generic.enter_all"), {
        position: "bottom-right",
      });
      return;
    }
    props.close();

    dispatch(
      registerLeaderProfile({
        token,
        mail,
        teaching_language,
        teaching_language_skill_level,
        interestedTopic,
        introduction,
        creditAdjustmentRate
      })
    );
  };

  // Generate an array of integers within a given range (e.g., -100 to 100)
  const generateIntegerOptions = (min, max) => {
    const options = [];
    for (let i = min; i <= max; i++) {
      options.push(i);
    }
    return options;
  };

  // Generate a range of integers, for example, from -100 to 100
  const integerOptions = generateIntegerOptions(0, 600);

  return (
    <>
      <Dialog open={props.open}>
        <DialogHeader className="flex justify-center">
          {t("become_a_leader.add_course")}
        </DialogHeader>
        <DialogBody>
          <div className="flex flex-col gap-8">
            <Select
              variant="outlined"
              label={t("become_a_leader.teaching_lang")}
              className="h-[40px] text-2xl"
              onChange={(val) => setTeaching_language(val)}
            >
              <Option value="EN">{t("generic.english")}</Option>
              <Option value="ES">{t("generic.spanish")}</Option>
              <Option value="CN">{t("generic.chinese")}</Option>
            </Select>
            <Select
              variant="outlined"
              label={t("become_a_leader.teaching_lang_level")}
              className="h-[40px] text-2xl"
              onChange={(val) => setTeaching_language_skill_level(val)}
            >
              <Option value={"1"}>{t("generic.skill_1")}</Option>
              <Option value={"2"}>{t("generic.skill_2")}</Option>
              <Option value={"3"}>{t("generic.skill_3")}</Option>
              <Option value={"4"}>{t("generic.skill_4")}</Option>
              <Option value={"5"}>{t("generic.skill_5")}</Option>
            </Select>

            <Textarea
              label={t("become_a_leader.course_intro")}
              onChange={(e) => setIntroduction(e.target.value)}
            />
            <Textarea
              label={t("become_a_leader.topics")}
              onChange={(e) => setInterestedTopic(e.target.value)}
            />

            <div>
              <label>{t("become_a_leader.how_many_credits_1")}</label>
            </div>
            <div>
              <label>{t("become_a_leader.how_many_credits_2")}</label>
              <select value={creditAdjustmentRate} onChange={(e) => setCreditAdjustmentRate(e.target.value)}>
                {integerOptions.map((num) => (
                  <option key={num} value={num}>
                    {num}
                  </option>
                ))}
              </select><label>{t("become_a_leader.how_many_credits_3")}</label>
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            className="mr-1"
            onClick={props.close}
          >
            <span>{t("generic.cancel")}</span>
          </Button>
          <Button variant="gradient" color="green" onClick={sendData}>
            <span>{t("generic.add")}</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
