// Import action types
import { SET_AUTH_TOKEN, SET_LOG_OUT } from "../actions/auth.action";

// Define initial state
const initialState = {
  token: null,
  mail: null,
};

// Define reducer function
export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AUTH_TOKEN:
      return {
        ...state,
        token: action.payload.token,
        mail: action.payload.user,
      };
    case SET_LOG_OUT:
      return {
        ...state,
        token: null,
        mail: null,
      };
    default:
      return state; // Add a default case to handle other action types
  }
}
