import {
  GET_ALL_MEETING,
  GET_CURRENT_MEETING,
  SET_CREATE_MEETING,
  GET_REVIEWS,
  GET_PENDING_MEETINGS_TEACHER,
  GET_COMPLETED_MEETINGS_TEACHER,
  GET_PENDING_MEETINGS_STUDENT,
  GET_COMPLETED_MEETINGS_STUDENT
} from "../actions/meeting.action";

const initialState = {
  meetings: [],
  currentMeeting: {},
  currentReviews: [],
  getPendingMeetingTeacher: [],
  getCompletedMeetingTeacher: [],
  getPendingMeetingStudent: [],
  getCompletedMeetingStudent: [],
};

export default function meetingReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_MEETING:
      return {
        ...state,
        meetings: action.payload,
      };
    case GET_PENDING_MEETINGS_TEACHER:
      return {
        ...state,
        getPendingMeetingTeacher: action.payload,
      };
    case GET_COMPLETED_MEETINGS_TEACHER:
      return {
        ...state,
        getCompletedMeetingTeacher: action.payload,
      };
    case GET_PENDING_MEETINGS_STUDENT:
      return {
        ...state,
        getPendingMeetingStudent: action.payload,
      };
    case GET_COMPLETED_MEETINGS_STUDENT:
      return {
        ...state,
        getCompletedMeetingStudent: action.payload,
      };
    case SET_CREATE_MEETING:
      return {
        ...state,
        meetings: Array.isArray(state.meetings)
          ? [...state.meetings, action.payload]
          : [action.payload],
      };
    case GET_CURRENT_MEETING:
      return {
        ...state,
        currentMeeting: action.payload,
      };
    case GET_REVIEWS:
      return {
        ...state,
        currentReviews: action.payload,
      };
    default:
      return state;
  }
}
