import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { forgotPassword } from "../../store/actions/auth.action";
import { useTranslation } from "react-i18next";


export default function ForgotPassword() {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const handleColse = () => {
    navigation("/login");
  };

  const sendData = () => {
    if (!email) {
      toast.warn(t("generic.enter_all"), {
        position: "bottom-right",
      });
      return;
    }

    dispatch(forgotPassword(email, navigation));
  };

  return (
    <div className="max-w-[600px] mx-auto p-4 h-full">
      <div className="flex flex-col border-[#004225] border-[1px] gap-10 mt-40 p-4 md:p-20">
        <p className="text-2xl xl:text-3xl font-semibold text-center">
          Update your password
        </p>
        <p className="text-2xl font-normal text-center">
          Enter your email address and select Send Email.
        </p>
        <div className="flex flex-col items-center gap-4">
          <p className="flex text-xl md:text-2xl lg:text-3xl font-normal">
            Email:
          </p>
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="border-[1px] border-[#004225] text-center bg-[#F5F5DC] text-2xl w-[200px] md:w-[300px] lg:w-[400px]"
          />
        </div>
        <div className="flex justify-center gap-4">
          <button
            className="bg-[#004225] rounded-[5px] w-[70px] h-[30px] xl:w-[100px] xl:h-[40px] text-white"
            onClick={handleColse}
          >
            {t("generic.cancel")}
          </button>
          <button
            className="bg-[#004225] rounded-[5px] w-[70px] h-[30px] xl:w-[100px] xl:h-[40px] text-white"
            onClick={sendData}
          >
            Send Email
          </button>
        </div>
      </div>
    </div>
  );
}
