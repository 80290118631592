import React, { useState } from "react";

import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TeamUserCard from "../../components/Cards/TeamUserCard";
import { MenuBar } from "../../components/Menu/Menu";

import Alex from "../../assets/image/Alex.svg";
import Sania from "../../assets/image/Sania.svg";
import Jannat from "../../assets/image/Jannat.svg";

function OurTeam() {
  const [menuOpen, setMenuOpen] = useState(false);

  const closeMenuBar = () => {
    setMenuOpen(false);
  };

  const openMenuBar = () => {
    setMenuOpen(true);
  };
  return (
    <div className="max-w-screen-2xl mx-auto px-4">
      <Header openMenu={openMenuBar} />
      <div>
        <div className="hidden xl:block absolute w-full h-[140px] bg-[#004225] mt-[400px]" />
        <div className="text-6xl font-bold ml-12 text-[#004225] z-10">
          Our Team
        </div>
        <div className="text-3xl font-normal mt-4 ml-12 z-10">
          Here is our company Founder, CEO and VP.
        </div>
        <div className="flex flex-col items-center relative">
          <div className="grid grid-cols-1 xl:grid-cols-3 gap-40 mt-24 mb-60 ml-10 z-10">
            <TeamUserCard
              name={"Alex Bhatti"}
              image={Alex}
              job={"Founder"}
              surmary={
                "I am the founder of The  Lingo Club and put all my effort and time to make the organization to grow and shine with the peoples."
              }
            />
            <TeamUserCard
              className="mt-20"
              image={Sania}
              name={"Sania Mirza"}
              job={"C E O"}
              surmary={
                "I am the C E O of The  Lingo Club and I am doing hard work for the company to benefits both the learner and leader."
              }
            />
            <TeamUserCard
              className="mt-40"
              image={Jannat}
              name={"Jannat Mirza"}
              job={"V P"}
              surmary={
                "I am the V P of The  Lingo Club, I am doing a great job by putting my hardship to put the company on next level of success."
              }
            />
          </div>
        </div>
      </div>
      <Footer />
      <MenuBar open={menuOpen} close={closeMenuBar} />
    </div>
  );
}

export default OurTeam;
