import React from "react";

export default function MeetingTimeButton(props) {
  return (
    <button
      className={`w-[100px] md:w-[140px] rounded-2xl border-2 border-solid border-[#004225] text-xl md:text-2xl xl:text-3xl font-normal ${
        props.nowState === props.time
          ? "bg-[#004225] text-[#F5F5F5]"
          : "bg-[#F5F5F5] text-[#000000]"
      } `}
      onClick={props.onClick}
    >
      {props.time}
    </button>
  );
}
