import { toast } from "react-toastify";
import axios from "axios";
import {base_url} from "../../components/Transformation/Transformations"

export const GET_PAYMENT_KEY = "GET_PAYMENT_KEY";
export const GET_TRANSACTION_LIST = "GET_TRANSACTION_LIST";
export const GET_TRANSACTION_LIST_LOADER = "GET_TRANSACTION_LIST_LOADER";

export const getPaymentKey = ({ amount, token }) => async (dispatch) => {
  try {
    const response = await axios.post(
      base_url + "/clubusers/create-payment-intent",
      { amount, payment_method: "card" },
      {
        headers: {
          Authorization: "Token " + token,
        },
      }
    );
    dispatch({
      type: GET_PAYMENT_KEY,
      payload: { ...response.data },
    });
    return response.data.clientSecret; 
  } catch (error) {
    const message = error.response
      ? error.response.data.status
      : "An unexpected error occurred";
    toast.error(message, {
      position: "bottom-right",
    });
    throw error; // Re-throw the error so it can be handled by the caller
  }
};

export const getTransactionList = ({ token, page }) => async (dispatch) => {
  try {
    const response = await axios.get(`${base_url}/transactions/?page=${page ? page : 1}`, {
      headers: {
        Authorization: "Token " + token,
      },
    });
    dispatch({ type: GET_TRANSACTION_LIST_LOADER, payload: false});
    return dispatch({ type: GET_TRANSACTION_LIST, payload: response.data });
  } catch (error) {
    const message = error.response
      ? error.response.data.status
      : "An unexpected error occurred";
    toast.error(message, {
      position: "bottom-right",
    });
    throw error;
  }
};
