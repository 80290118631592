import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useState } from "react";
import { toast } from "react-toastify";
import { Button } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

export default function PaymentForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { amount } = props;
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsOpen(false);

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://lingoclub.org/schedule-meeting",
      },
      redirect: "if_required",
    });

    if ((result.error || error) && !loading) {
      setError(result.error.message);
      setLoading(false);
    } else {
      if (result.paymentIntent.status === "succeeded") {
        toast.success("Payment was processed correctly.", {
          position: "bottom-right",
        });
        setError(null);
        setLoading(false);
      }
      navigate("/schedule-meeting");
    }
  };

  return (
    <div>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-75">
          <div className="bg-white p-12 rounded-lg shadow-lg w-96 flex flex-col items-center">
            <p className="mb-8 text-lg text-center">
              Proceed to Pay ${amount / 100}?
            </p>
            <div className="flex space-x-6">
              <Button
                onClick={(event) => {
                  handleSubmit(event);
                }}
                disabled={!stripe}
                className="px-6 py-3 text-lg"
              >
                Yes
              </Button>
              <Button
                onClick={() => {
                  setIsOpen(false);
                }}
                className="px-6 py-3 text-lg"
              >
                No
              </Button>
            </div>
          </div>
        </div>
      )}
      <form>
        <PaymentElement />
        <div className="flex justify-center mt-8 gap-3">
          <Button type="button" onClick={() => window.location.reload()}>
            Go Back
          </Button>
          <Button
            type="button"
            className="bg-[#004225] rounded-[5px] w-[70px] h-[30px] xl:w-[100px] xl:h-[40px] text-white"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Pay
          </Button>
        </div>
      </form>
    </div>
  );
}
