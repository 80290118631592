import { Button, Card, Typography } from "@material-tailwind/react";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


export default function TimeTable(props) {
  const { t } = useTranslation();
  const TABLE_HEAD = ["No", "Day", "Time", "Note", "Actions"];
  const userProfile = useSelector((state) => state.user.currentUser);
  const userTimeZone = userProfile?.timezone || moment.tz.guess(); // Use user's timezone or guess if not available

  return (
    <Card className="max-h-[600px] w-full overflow-scroll">
      <table className="w-full min-w-max table-auto bg-[#F5F5DC] text-center">
        <thead>
          <tr>
            {TABLE_HEAD?.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 p-4 bg-[#F5F5DC]"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props?.TABLE_ROWS?.map(({ day, hour, minute, note }, index) => {
            const isLast = index === props.TABLE_ROWS.length - 1;
            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

            // Construct a UTC date-time string from the provided day, hour, and minute
            const timeUTC = moment.tz(
              `${day} ${hour}:${minute}`,
              "ddd HH:mm",
              "UTC"
            );

            // Convert the UTC date-time to the user's selected timezone
            const localTime = timeUTC.clone().tz(userTimeZone);

            // Extract the correct local day and time
            const localDay = localTime.format("ddd");
            const localHourMinute = localTime.format("HH:mm");

            return (
              <tr key={index + 1}>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {index + 1}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {localDay}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {localHourMinute}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {note}
                  </Typography>
                </td>
                <td className={classes}>
                  <Button color="red" onClick={() => props?.delete(index)}>
                    {t("generic.delete")}
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
  );
}
