import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Username from "../../assets/image/Username.svg";
import Password from "../../assets/image/Password.svg";
import Email from "../../assets/image/Email.svg";
import TimeZoneIcon from "../../assets/image/timezone.png";
import { useTranslation } from "react-i18next";
import { register } from "../../store/actions/auth.action";


export default function Register() {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [match, setMatch] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const validate = useCallback(() => {
    if (password !== confirmPassword) setMatch(1);
    else setMatch(0);
  }, [password, confirmPassword]);

  useEffect(() => {
    validate();
  }, [password, confirmPassword, validate]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    }

    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen]);

  const sendData = () => {
    if (!firstName || !lastName || !email || !password || !confirmPassword || !timeZone) {
      toast.warn(t("login.all_info"), {
        position: "bottom-right",
      });
      return;
    }

    if (match) {
      toast.warn(t("register.check_password"), {
        position: "bottom-right",
      });
      return;
    }

    dispatch(
      register({
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
        timeZone,
        navigation,
      })
    );
  };

  return (
    <div className="flex w-full h-full">
      <div className="flex flex-col items-center md:basis-1/2 bg-[#F5F5DC] w-[1440px]">
        <div className="flex flex-col gap-12 mt-[60px] items-center">
          <div class="relative">
            <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
              <img src={Username} alt="Vector" className="pl-1 lg:pl-5" />
            </div>
            <input
              key="first name"
              type="text"
              id="input-group-1"
              className="bg-[#DDE3CF] pl-12 md:pl-20 w-[250px] md:w-[300px] xl:w-[400px] 2xl:w-[450px] h-[50px] md:h-[60px] rounded-3xl text-lg md:text-xl xl:text-2xl text-[#000000]"
              placeholder={t("user_home.first_name")}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
              <img src={Username} alt="Vector" className="pl-1 lg:pl-5" />
            </div>
            <input
              key="last name"
              type="text"
              id="input-group-2"
              className="bg-[#DDE3CF] pl-12 md:pl-20 w-[250px] md:w-[300px] xl:w-[400px] 2xl:w-[450px] h-[50px] md:h-[60px] rounded-3xl text-lg md:text-xl xl:text-2xl text-[#000000]"
              placeholder={t("user_home.last_name")}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
              <img src={Email} alt="Vector" className="pl-1 lg:pl-5" />
            </div>
            <input
              key="email"
              type="text"
              id="input-group-3"
              className="bg-[#DDE3CF] pl-12 md:pl-20 w-[250px] md:w-[300px] xl:w-[400px] 2xl:w-[450px] h-[50px] md:h-[60px] rounded-3xl text-lg md:text-xl xl:text-2xl text-[#000000]"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
              <img src={Password} alt="Vector" className="pl-1 lg:pl-5" />
            </div>
            <input
              key="password"
              type="password"
              id="input-group-4"
              className="bg-[#DDE3CF] pl-12 md:pl-20 w-[250px] md:w-[300px] xl:w-[400px] 2xl:w-[450px] h-[50px] md:h-[60px] rounded-3xl text-lg md:text-xl xl:text-2xl text-[#000000]"
              placeholder={t("generic.password")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
              <img src={Password} alt="Vector" className="pl-1 lg:pl-5" />
            </div>
            <input
              key="confirmpassword"
              type="password"
              id="input-group-5"
              className="bg-[#DDE3CF] pl-12 md:pl-20 w-[250px] md:w-[300px] xl:w-[400px] 2xl:w-[450px] h-[50px] md:h-[60px] rounded-3xl text-lg md:text-xl xl:text-2xl text-[#000000]"
              placeholder={t("register.confirm_password")}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
              <img
                src={TimeZoneIcon}
                alt="timezone"
                className="pl-1 lg:pl-5 h-[29px]"
              />
            </div>
            <select
              type="select"
              className="bg-[#DDE3CF] pl-12 md:pl-20 w-[250px] md:w-[300px] xl:w-[400px] 2xl:w-[450px] h-[50px] md:h-[60px] rounded-3xl text-lg md:text-xl xl:text-2xl text-[#000000]"
              label="select timezone"
              value={timeZone}
              onChange={(e) => setTimeZone(e.target.value)}
            >
              <option value="" className="text-gray-500">{t("register.select_timezone")}</option>
              <option value="US/Pacific">{t("generic.timezone_pacific")}</option>
              <option value="US/Eastern">{t("generic.timezone_eastern")}</option>
              <option value="US/Mountain">{t("generic.timezone_mountain")}</option>
              <option value="US/Central">{t("generic.timezone_central")}</option>
              <option value="PRC">{t("generic.timezone_prc")}</option>
            </select>
          </div>
        </div>
        <div className="text-red-900">
          {match === 1 && t("register.password_mismatch")}
        </div>
        <div className="flex">
          <input
            type="checkbox"
            id="link-checkbox"
            className="rounded-3xl w-[20px] h-[20px] xl:w-[30px] xl:h-[30px] mt-12 xl:mt-12 border-[#004225]"
            checked={isChecked}
            onClick={() => setIsChecked(!isChecked)}
          />
          <label
            htmlFor="link-checkbox"
            className="mt-11 ml-2 text-lg md:text-xl xl:text-3xl"
          >
            {t("register.i_agree")}
          </label>
          <label
            htmlFor="link-checkbox"
            className="text-[#004225] ml-2.5 mt-11 text-lg md:text-2xl xl:text-3xl font-bold"
            onClick={openModal}
          >
            {t("register.term")}
          </label>
        </div>
        <div className="flex flex-col gap-4 mt-8">
          <button
            className={`w-[200px] h-[50px] ml:w-[300px] md:h-[60px] bg-[#004225] rounded-[30px] text-2xl xl:text-3xl text-[#F5F5F5] font-bold ${
              !isChecked && "opacity-50"
            }`}
            onClick={sendData}
            disabled={!isChecked}
          >
            {t("header.register")}
          </button>
        </div>
        <div className="flex gap-4 mb-24 mt-6">
          <label className="mt-6 text-lg md:text-xl xl:text-2xl 2xl:text-3xl">
            {t("register.already_member")}
          </label>
          <label className="mt-6 text-xl md:text-2xl xl:text-4xl font-bold text-[#004225]">
            {" "}
            <Link to={"/login"}>{t("login.login")}</Link>
          </label>
        </div>
      </div>
      <div className="hidden md:block basis-1/2 bg-[#004225]">
        <div className="flex flex-col items-center justify-center h-full">
          <span className="text-6xl xl:text-7xl 2xl:text-8xl font-bold leading-30 text-[#FFFFFF]">
            {t("header.register")}
          </span>
        </div>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
          <div
            ref={modalRef}
            className="bg-[#e5f7ed] p-8 rounded-3xl shadow-lg w-[60%] border-4 border-[#8fbc8f] max-h-[90vh] overflow-hidden"
          >
            <h2 className="text-2xl font-bold mb-4 underline">
              Terms & Conditions:
            </h2>
            <div className="max-h-[70vh] overflow-y-auto">
              <section className="text-gray-900 mb-4 font-bold text-xl">
                1. Acceptance of Terms
                <p className="font-medium text-base">
                  Welcome to Lingo Club! By accessing or using the LingoClub
                  platform (the "Service"), you agree to be bound by these Terms
                  and Conditions. If you disagree with any part of the terms,
                  you may not access the Service.
                </p>
              </section>
              <section className="text-gray-900 mb-4 font-bold text-xl">
                2. Description of Service
                <p className="font-medium text-base">
                  LingoClub is an online platform that facilitates language
                  learning meetings between learners and leaders. Learners pay
                  for meetings using Lingo Credits, and leaders earn Lingo
                  Credits for conducting meetings.
                </p>
              </section>
              <section className="text-gray-900 mb-4 font-bold text-xl">
                3. User Registration
                <p className="font-medium text-base">
                  You must register for an account to use the Service. You agree
                  to provide accurate, current, and complete information during
                  the registration process. You are responsible for maintaining
                  the confidentiality of your account and password.
                </p>
              </section>
              <section className="text-gray-900 mb-4 font-bold text-xl">
                4. Lingo Credits
                <p className="font-medium text-base">
                  Lingo Credits are used as the currency for transactions on
                  Lingo Club. Learners purchase Lingo Credits, which can be used
                  to book meetings. Leaders earn Lingo Credits based on the
                  duration of the meetings conducted, with a standard rate set
                  for a meeting of 15-minute or above. The rate may vary
                  depending on the service level and the Leader's expertise.
                </p>
              </section>
              <section className="text-gray-900 mb-4 font-bold text-xl">
                5. Payments and Earnings
                <p className="font-medium text-base">
                  Payments for Lingo Credits can be made using various payment
                  methods provided on our platform. Once purchased, Lingo
                  Credits cannot be refunded but can be used within the platform
                  for booking meetings. Leaders can redeem earned Lingo Credits
                  for monetary payment according to LingoClub's payout policies.
                </p>
              </section>
              <section className="text-gray-900 mb-4 font-bold text-xl">
                6. User Responsibilities
                <p className="font-medium text-base">
                  Users are responsible for their conduct while using our
                  platform. You agree not to use the platform for any unlawful
                  or prohibited activities. Lingo Club reserves the right to
                  suspend or terminate your account if you violate these Terms.
                </p>
              </section>
              <section className="text-gray-900 mb-4 font-bold text-xl">
                7. Intellectual Property
                <p className="font-medium text-base">
                  All content provided by Leaders on the platform is their
                  responsibility. Lingo Club is not liable for any content
                  posted or shared by users. Users grant Lingo Club a
                  non-exclusive, royalty-free, worldwide license to use,
                  distribute, and display their content for the purpose of
                  operating the platform.
                </p>
              </section>
              <section className="text-gray-900 mb-4 font-bold text-xl">
                8. Termination
                <p className="font-medium text-base">
                  Lingo Club reserves the right to terminate or suspend access
                  to the platform without prior notice if you violate these
                  Terms or engage in fraudulent or illegal activities.
                </p>
              </section>
              <section className="text-gray-900 mb-4 font-bold text-xl">
                9. Changes to Terms and Conditions
                <p className="font-medium text-base">
                  We may update these Terms from time to time. Any changes will
                  be posted on this page, and continued use of the platform
                  constitutes acceptance of the new Terms.
                </p>
              </section>
              <section className="text-gray-900 mb-4 font-bold text-xl">
                10. Contact Us
                <p className="font-medium text-base">
                  For any questions or concerns regarding these Terms, please
                  contact us at [insert contact information like Email or
                  contact number].
                </p>
              </section>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
