import React from "react";

export default function TeamUserCard(props) {
  return (
    <div className={`w-[300px] ${props.className || ""}`}>
      <img
        src={props.image}
        alt="card"
        className="rounded-[10px] w-[300px] h-[350px] border-[#004225] border-[3px]"
      />
      <div>
        <div className="text-4xl mt-6 font-semibold text-[#004225]">
          {props.name}
        </div>
        <div className="text-2xl mt-3 font-normal">{props.job}</div>
        <div className="text-base mt-3 font-light">{props.surmary}</div>
      </div>
    </div>
  );
}
