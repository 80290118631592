import React, { useCallback, useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import ActiveLingoCard from "../../components/Cards/ActiveLingoCard";
import Pagination from "../../components/Pagination/Pagination";
import { MenuBar } from "../../components/Menu/Menu";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllShortLeaderUser,
  FilterLeaderByLanguage,
} from "../../store/actions/user.action";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader/Loader";

function Leaders() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchLanguage, setSearchLanguage] = useState("");
  const [activeButton, setActiveButton] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const { t } = useTranslation();

  const allShortLeaders = useSelector((state) => state.user.allShortLeaders);

//  useEffect(() => {
//    dispatch(getAllShortLeaderUser(token, page));
//    //console.log('hui', allShortLeaders, page);
//  }, [dispatch, token, page]);

  const fetchLeaders = useCallback(
    async (currentPage) => {
      setLoading(true);
      await dispatch(getAllShortLeaderUser(token, currentPage));
      setLoading(false);
    },
    [token, dispatch]
  );

  useEffect(() => {
    if (token) {
      fetchLeaders(page);
    }
  }, [token, page, fetchLeaders]);

  // Filter and set profiles when allUsers or searchLanguage changes
  // It is OK for a user to see his own leader profile.
  // If a user tries to schedule a meeting with himself, the backend will reject it. There is no need to filter here.
//  useEffect(() => {
//    if (allShortLeaders?.results) {
//      setFilteredProfiles(allShortLeaders.results);
//    }
//  }, [allShortLeaders]);

  const numberOfPages = Math.ceil(allShortLeaders?.count / 10) || 0;

  useEffect(() => {
    setPage(1);
  }, [searchLanguage]);

  const closeMenuBar = () => {
    setMenuOpen(false);
  };

  const openMenuBar = () => {
    setMenuOpen(true);
  };

  const handleBtn = (buttonId, language) => {
    dispatch(FilterLeaderByLanguage(token, language));
    setActiveButton(buttonId);
    setSearchLanguage(language);
  };

  const handleClick = () => {
    if (!token) {
      toast.warn(t("generic.please_login"), {
        position: "bottom-right",
      });
      return;
    } else {
      navigate("/make-a-wish");
    }
  };

  return (
    <div
      className="max-w-screen-2xl mx-auto px-4"
      data-testid="active-lingo-card"
    >
      <Header openMenu={openMenuBar} />
      <div className="flex flex-col gap-0">
        <div className="flex justify-center">
          <span className="font-semibold text-2xl md:text-3xl xl:text-4xl overline decoration-green-950">
            {t("leader_page.I")}
          </span>
          <span className="font-semibold text-2xl md:text-3xl xl:text-4xl">
            {t("leader_page.title")}
          </span>
        </div>

        <div className="text-xl lg:text-2xl mt-4 font-normal text-center py-2">
          {t("leader_page.title_text")}
        </div>

        <div className="flex flex-col lg:flex-row items-center py-10 gap-4 justify-center">
          <div className="flex flex-row justify-center items-center">
            <button
              className={`w-[70px] h-[30px] xl:w-[100px] xl:h-[40px] hover:bg-[#133e2b] ${
                activeButton === 1
                  ? "transparent text-black border border-[#195d40]"
                  : "bg-[#195d40] text-white border-r border-white-50"
              } `}
              onClick={() => handleBtn(1, "EN")}
            >
              {t("leader_page.e_button")}
            </button>
            <button
              className={`w-[70px] h-[30px] xl:w-[100px] xl:h-[40px] hover:bg-[#133e2b] ${
                activeButton === 2
                  ? "transparent text-black border-t border-b border-[#195d40]"
                  : "bg-[#195d40] text-white"
              } `}
              onClick={() => handleBtn(2, "CN")}
            >
              {t("leader_page.c_button")}
            </button>
            <button
              className={`w-[70px] h-[30px] xl:w-[100px] xl:h-[40px] hover:bg-[#133e2b] ${
                activeButton === 3
                  ? "transparent text-black border-t border-b border-r border-[#195d40]"
                  : "bg-[#195d40] text-white border-l border-white-50"
              } `}
              onClick={() => handleBtn(3, "ES")}
            >
              {t("leader_page.s_button")}
            </button>
          </div>
        </div>
      </div>
      <div className="pb-[2rem]">
        {loading ? (
          <Loader />
        ) : allShortLeaders ? (
          <div className="grid sm:grid-cols-1 lg:grid-cols-2 md:gap-10 gap-5  max-w-[1100px] mx-auto my-0  w-full mb-4">
            {allShortLeaders.results?.map((leader) => (
              <ActiveLingoCard
                id={leader.id}
                key={leader.id}
                image={leader.userImage}
                name={leader.owner.first_name + " " + leader.owner.last_name}
                teaching_language={leader.teaching_language}
                reviewCount={leader?.owner?.review_count}
                reviewScore={leader?.owner?.review_score}
              />
            ))}
          </div>
        ) : (
          <p className="font-semibold text-xl">
            {t("leader_page.no_leader_text")}
          </p>
        )}
      </div>
      <div className="flex justify-center">
        <Pagination pageNumber={numberOfPages} setPage={setPage} page={page} />
      </div>
      <div className="flex justify-center text-lg pl-16 mt-7 font-bold text-gray-800 ">
        {t("leader_page.note")}
        <p
          onClick={() => {
            handleClick();
          }}
          className="underline max-w-[45rem] ml-2 cursor-pointer"
        >
          {t("leader_page.note_text")}
        </p>
      </div>
      <Footer />
      <MenuBar open={menuOpen} close={closeMenuBar} />
    </div>
  );
}

export default Leaders;
